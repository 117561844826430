<template>
  <div>
    <!-- eslint-disable -->

    <!-- Filters -->
    <sw-filters
      v-if="!contactThread && !thread"
      :shorts="[
          {
              title: $t('Status'),
              value: () => filters.status ? $t(complaintStatusByValue(filters.status)) : null,
              clear: () => { filters.status = ''; }
            },
            {
              title: $t('CreatedTime'),
              value: () => filters.createdAt,
              clear: () => { filters.createdAt = ''; }
            },
      ]"
      :res="[
            {
              title: $t('All'),
              value: () => pagination.totalRecords,
            }
          ]"
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="4"
        >
          <sw-select :name="$t('Status')">
            <v-select
              v-model="filters.status"
              :options="complaintStatuses"
              :reduce="i => i.value"
            >
              <template #option="{ label }">
                {{ $t(label) }}
              </template>
              <template #selected-option="{ label }">
                {{ $t(label) }}
              </template>
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="4"
        >
          <sw-select :name="$t('CreatedTime')">
            <sw-select-fpr
              :is-empty="filters.createdAt"
              @clear="filters.createdAt = ''"
            >
              <flat-pickr
                v-model="filters.createdAt"
                class="form-control"
                :placeholder="$t('CreatedTime')"
                :config="{ mode: 'range', locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="4"
        />
        <b-col
          sm="12"
          md="6"
          lg="4"
        >
          <b-form-checkbox
            v-model="filters.showWithoutNotes"
            variant="primary"
          >
            {{ $t('ShowCompletitionsWithOutNotes') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </sw-filters>

      <div class="my-50">
          <b-button
                  v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_COMPLAINT_ADD])"
                  size="sm"
                  variant="primary"
                  @click="editModal = { show: true, item: JSON.parse(JSON.stringify(defaultComplaint)) }"
          >
              <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
              />
              {{ $t('Add') }}
          </b-button>
      </div>
    <!-- Table -->
    <component :is="tag">
      <sw-table
        table-id="61cf3f0cd5ec4c1db67978fe6dee3a09"
        show-columns
        show-search
        :fields.async="fields"
        :pagination="pagination"
        @change-pagination="Object.assign(pagination, $event)"
        @change-search-query="search = $event"
        @reload-content="loadData"
        @set-fields="fields = $event"
      >
        <template #table>
          <!--      Table      -->
          <b-table
            :items="items"
            :fields="tableFields"
            striped
            responsive
            :style="{ minHeight: tableSize(items, 250) }"
            :busy="loading"
            show-empty
            :sort-by.sync="sorter.sortBy"
            :sort-desc.sync="sorter.sortDesc"
            :no-local-sorting="true"
            @sort-changed="Object.assign(sorter, $event); loadData()"
          >
            <!--    Head    -->
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>
            <template #cell(id)="{ index }">
              #{{ getOrderNo(index, pagination, sorter) }}
            </template>

            <template #cell(name)="{ item }">
              <router-link
                v-if="item.contactThread && item.contactThread.id"
                :class="{ 'deleted-text': item.contactThread.contact.deletedAt }"
                :to="`/contact/${item.contactThread.contact.id}/thread/${item.contactThread.id}/timeline`"
              >
                {{ getUserName(item) }}
              </router-link>
                <span v-else-if="item.firstName || item.lastName">
                    {{ item.firstName || '' }} {{ item.lastName || '' }}
                </span>
            </template>

            <template #cell(address)="{ item }">
              {{ getAddress(item) }}
            </template>

<!--            <template #cell(orderDate)="{ value }">-->
<!--              <span v-if="value.date">{{ formatDate(value.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, $i18n.locale) }}</span>-->
<!--              <feather-icon-->
<!--                v-else-->
<!--                icon="MinusIcon"-->
<!--              />-->
<!--            </template>-->

<!--            <template #cell(status)="{ value }">-->
<!--              <b-badge :variant="complaintStatusVariantByValue(value)">-->
<!--                {{ $t(complaintStatusByValue(value)) }}-->
<!--              </b-badge>-->
<!--            </template>-->

            <template #cell(note)="{ value, item }">
              <div
                v-if="value"
                class="text-primary cursor-pointer"
                @click="noteModal.item = { ...item }; noteModal.show = true; noteModal.isPreview = true"
              >
                <feather-icon icon="EyeIcon" />
                {{ value | truncate(50) }}
              </div>
              <div v-else>
                <b-button
                  class="btn-icon"
                  icon
                  variant="gradient-primary"
                  size="sm"
                  @click="noteModal.item = {
                    ...item,
                    note: '',
                  }; noteModal.show = true; noteModal.isPreview = false"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </div>
            </template>

              <template #cell(phoneNumber)="{ value }">
                  <b-badge variant="light-primary" v-if="value">
                      {{ value.phone }}
                  </b-badge>
                  <feather-icon
                v-else
                icon="MinusIcon"
              />
              </template>
              <template #cell(emailAddress)="{ value }">
                  <b-badge variant="light-primary" v-if="value">
                      {{ value.mail }}
                  </b-badge>
                  <feather-icon
                v-else
                icon="MinusIcon"
              />
              </template>


            <template #cell(action)="{ item, index }">
              <b-dropdown
                v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_COMPLAINT_EDIT, $roles.CONTACT_THREAD_COMPLAINT_DELETE])"
                id="dropdown-dropleft"
                dropleft
                no-caret
                variant="flat"
              >
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" />
                </template>
                <span>
                  <span v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_COMPLAINT_EDIT])">
                    <b-dropdown-item @click="updateField(item, 'status', 'DURING')">
                        <dropdown-item-label icon="CircleIcon"
                                               :label="$t('During')"
                          />
                    </b-dropdown-item>
                    <b-dropdown-item @click="updateField(item, 'status', 'ACCEPTED', true)">
                        <dropdown-item-label icon="CheckIcon"
                                               :label="$t('AgreementStatuses.ACCEPTED')"
                          />
                    </b-dropdown-item>
                    <b-dropdown-item @click="updateField(item, 'status', 'REJECTED', true)">
                        <dropdown-item-label icon="XIcon"
                                               :label="$t('Rejected')"
                          />
                    </b-dropdown-item>
                    <b-dropdown-divider />
                  </span>
                    <b-dropdown-item
                            v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_COMPLAINT_EDIT])"
                            @click="openEditModal(item)"
                    >
                        <dropdown-item-label icon="EditIcon"
                                               :label="$t('Edit')"
                          />
                  </b-dropdown-item>
                  <b-dropdown-item
                          v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_COMPLAINT_DELETE])"
                          @click="deleteItem(item.id, index)"
                  >
                      <dropdown-item-label icon="TrashIcon"
                                               :label="$t('Delete')"
                          />
                  </b-dropdown-item>
                </span>
              </b-dropdown>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <!--    Other    -->
              <!--    Other    -->
              <template #cell()="row">
                  <table-default-cell
                          :field="row.field"
                          :value="row.value"
                  />
              </template>

              <!--      Empty      -->
              <template #empty>
                  <empty-content />
              </template>

              <!--      Table Busy      -->
              <template #table-busy>
                  <div class="text-center text-danger my-2">
                      <b-spinner
                              class="align-middle"
                              variant="primary"
                      />
                  </div>
              </template>
          </b-table>
        </template>
      </sw-table>
    </component>

    <!-- Note Modal -->
    <b-modal
      v-model="noteModal.show"
      :title="noteModal.isPreview ? $t('Preview') : $t('AddNote')"
      hide-footer
      no-close-on-backdrop
    >
      <div v-if="noteModal.item && !noteModal.isPreview">
        <b-form-group :label="$t('Content')">
          <b-form-textarea v-model="noteModal.item.note" />
        </b-form-group>
      </div>
      <div v-else-if="noteModal.item">
        <span v-html="noteModal.item.note.replaceAll('\n', '<br>')"></span>
      </div>

      <b-button
        v-if="!noteModal.isPreview"
        size="sm"
        variant="primary"
        @click="saveItem"
      >
        {{ $t('Save') }}
      </b-button>
    </b-modal>
    <!-- Complaint Form Modal -->
    <b-modal
      v-model="editModal.show"
      size="lg"
      :title="editModal.item && editModal.item.id ? $t('Edit') : $t('Add')"
      hide-footer
      no-close-on-backdrop
    >
      <div v-if="editModal.show">
        <complain-form
          :edited-item="editModal.item"
          @close-modal="editModal.show = false; editModal.item = null; loadData()"
        />
      </div>
    </b-modal>

  </div>
</template>

<script>
import { BFormTextarea, BTable, BDropdownDivider } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import axiosIns from '@/libs/axios'
import { complaintStatusByValue, complaintStatusVariantByValue, complaintStatuses } from '@/@types/complainStatus'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapGetters } from 'vuex'
import Form from './Form.vue'

const defaultComplaint = {
  contactThreadCartOrder: '',
  contactThread: '',
  orderNumber: '',
  orderDate: '',
  firstName: '',
  lastName: '',
  addressCountry: '',
  addressCity: '',
  addressPostalCode: '',
  addressStreet: '',
  addressNumber: '',
  note: '',
  phone: '',
  countryCode: '+48 ',
  email: '',
}

export default {
  name: 'List',
  components: {
    vSelect,
    BTable,
    complainForm: Form,
    BFormTextarea,
    BDropdownDivider,
    flatPickr,
  },
  props: {
    tag: {
      default: 'b-card',
      type: String,
    },
    showFilters: {
      default: true,
      type: Boolean,
    },
    contactThread: {
      default: null,
      type: Object,
      required: false,
    },
  },
  data: () => ({
    complaintStatuses,
    defaultComplaint,
    loading: false,
    items: [],
    filters: {
      status: '',
      createdAt: '',
      showWithoutNotes: false,
    },
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
    },
    search: '',

    noteModal: {
      show: false,
      item: null,
      isPreview: false,
    },

    editModal: {
      show: false,
      item: null,
    },

    fields: [
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      { key: 'name', label: 'FirstNameAndLastName', visible: true },
      { key: 'phoneNumber', label: 'Phone', visible: true },
      { key: 'emailAddress', label: 'Email', visible: true },
      { key: 'orderNumber', label: 'Order', visible: true },
      { key: 'address', label: 'Address', visible: true },
      {
        key: 'status',
        label: 'Status',
        visible: true,
        translate: true,
        valueFormatter: complaintStatusByValue,
        tag: 'b-badge',
        props: {
          variantFormatter: complaintStatusVariantByValue,
        },
      },
      { key: 'note', label: 'Note', visible: true },
      {
        key: 'orderDate', label: 'OrderDate', visible: true, hideTime: true,
      },
      {
        key: 'createdAt', label: 'CreatedAt', visible: true,
      },
      {
        key: 'createdBy', label: 'CreatedBy', visible: true, tag: 'avatar',
      },
      { key: 'action', label: 'Action', visible: true },
    ],
  }),
  computed: {
    ...mapGetters({
      thread: 'contact/thread',
    }),
    tableFields() {
      return this.fields.filter(field => field.visible)
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.loadData()
      },
    },
  },
  async mounted() {
    if (this.contactThread) {
      this.defaultComplaint.contactThread = this.contactThread
    } else if (this.thread) {
      this.defaultComplaint.contactThread = this.thread
    }

    await this.loadData()
  },
  methods: {
    complaintStatusByValue,
    complaintStatusVariantByValue,

    formatDate,
    async loadData() {
      this.loading = true
      try {
        const params = {
          fields_load: 'COMPLAINT_FULL',
          // fields_info: 1,
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
        }

        if (this.contactThread) {
          params['eq_contactThread-id'] = this.getObjectId(this.contactThread)
        }

        if (this.filters.status) {
          params.eq_status = this.filters.status
        }

        if (this.filters.createdAt) {
          const value = this.filters.createdAt.dateRange()
          if (value[0]) {
            // eslint-disable-next-line prefer-destructuring
            params.gte_createdAt = value[0]
          }
          if (value[1]) {
            // eslint-disable-next-line prefer-destructuring
            params.lte_createdAt = value[1]
          }
        }
        if (this.filters.showWithoutNotes) {
          params.eq_note = ''
        }

        if (this.sorter) {
          const sortDir = this.sorter?.sortDesc ? 'desc' : 'asc'
          const sortBy = this.sorter?.sortBy || 'id'
          params.orderBy = `${sortBy}.${sortDir}`
        }

        if (this.search.length) {
          const searchString = this.search.replaceAll(' ', '%')
          params.search = `%${searchString}%`
          params.search_fields = [
            'contactThreadCartOrder-number',
            'contactThread-contact-firstName',
            'contactThread-contact-lastName',
            'orderNumber',
            'firstName',
            'lastName',
          ]
        }

        const resp = await axiosIns.get('1/contacts/threads/complaint', { params })

        if (resp.data.data.items) {
          this.items = resp.data?.data?.items || []
          this.pagination.totalRecords = resp.data.data.totalItemCount
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    async updateField({ id }, field, value, needConfirm = false) {
      if (needConfirm) {
        const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
        if (!confirmed) return
      }

      try {
        const payload = {
          id,
          [field]: value,
        }
        await axiosIns.patch('1/contacts/threads/complaint', payload)
        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        const complaint = this.items.findIndex(e => e.id === payload.id)
        if (complaint > -1) {
          this.$set(this.items[complaint], 'status', payload.status)
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    async deleteItem(itemId, index) {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed) return

      try {
        await axiosIns.delete('1/contacts/threads/complaint', { data: JSON.stringify([itemId]) })

        this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        this.$delete(this.items, index)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    async saveItem() {
      try {
        await axiosIns({
          url: '1/contacts/threads/complaint',
          method: 'PATCH',
          data: { note: this.noteModal.item.note, id: this.noteModal.item.id },
        })

        const complaint = this.items.findIndex(e => e.id === this.noteModal.item.id)
        if (complaint > -1) {
          this.$set(this.items[complaint], 'note', this.noteModal.item.note)
        }

        this.noteModal = {
          show: false,
          item: null,
          isPreview: false,
        }
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    openEditModal(item) {
      const editedItem = JSON.parse(JSON.stringify(item))

      this.editModal = {
        show: true,
        item: editedItem,
      }
    },
  },
}
</script>

<style scoped>

</style>
